// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-deals-page-js": () => import("./../../../src/templates/deals-page.js" /* webpackChunkName: "component---src-templates-deals-page-js" */),
  "component---src-templates-generic-page-js": () => import("./../../../src/templates/generic-page.js" /* webpackChunkName: "component---src-templates-generic-page-js" */),
  "component---src-templates-image-portfolio-js": () => import("./../../../src/templates/image-portfolio.js" /* webpackChunkName: "component---src-templates-image-portfolio-js" */),
  "component---src-templates-index-page-js": () => import("./../../../src/templates/index-page.js" /* webpackChunkName: "component---src-templates-index-page-js" */),
  "component---src-templates-text-portfolio-list-js": () => import("./../../../src/templates/text-portfolio-list.js" /* webpackChunkName: "component---src-templates-text-portfolio-list-js" */),
  "component---src-templates-years-js": () => import("./../../../src/templates/years.js" /* webpackChunkName: "component---src-templates-years-js" */)
}

